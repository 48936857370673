@import "../../../../Theme/colors.scss";

.projectCardContainer {
  width: 250px !important;
  height: 450px !important;
  background-color: $white !important;
  margin: 0 !important;
  margin-top: 15px !important;
}

.cardHeader {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 35%;
  background-position: center;
  background-size: cover;

  > img {
    height: 75px;
    width: 75px;
    margin: 2.5em auto 0;
  }

  > h5 {
    margin: auto 0 0.5em;
    color: $white;
    font-size: 1.5em;
  }
}

.position {
  width: 100%;
  text-align: center;
  color: $dark-grey;
  margin-top: 0.2em;
}

.cardText {
  color: $dark-grey !important;
  padding: 0.5em !important;

  p {
    font-size: 0.9em !important;
  }

  h5 {
    margin-bottom: 0 !important;
  }
}

.linksContainer {
  margin-top: 0.2em;
}
