@import "../../Theme/colors.scss";

.aboutSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 2em 2em;
  background: $turquoise;

  //Tablet View
  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  .header {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px;
    width: 150px;
    box-shadow: 2px 2px $white-faded;
    border: 2px solid $white;

    margin: 10px auto 60px;
    padding: 5px;
  }

  .aboutCols {
    display: flex;
    margin-bottom: 2em;
    justify-content: center;

    //Mobile View
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .leftSection,
  .rightSection {
    width: 45%;

    //Mobile View
    @media screen and (max-width: 768px) {
      padding: 0;
      width: 100%;
    }
  }
}

.leftSection {
  background-color: $navy;
  display: flex;
  flex-direction: column;
  padding: 1.5em;

  .myName {
    color: $white;
    text-align: center;
    width: 100%;
    font-size: 3em;
    margin: 0;
  }
}

.rightSection {
  background-color: $white;
}

.leftSection,
.rightSection {
  max-width: 600px;
  margin: 0 25px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 0;
  }
}

.profilePic {
  border-radius: 50%;
  width: 80%;
  margin: 1em auto;
  align-self: center;
}

.portholeContainer {
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.subHeaderContainer {
  color: $white !important;
  font-size: 1em !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > h4 {
    margin: 0;
  }
}

.proficiencies {
  display: flex;
  flex-direction: column;
  text-align: center;

  > h3 {
    color: $white;
    margin: 2em 0 0.5em;
  }
}

.techContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
