@import "../../../Theme/colors.scss";

.techIconContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  > i {
    color: $white !important;
  }

  > p {
    margin: 0 !important;
    color: $white;
  }
}
