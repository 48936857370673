.contactContainer {
  min-height: 600px;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.linkBubble {
  position: absolute;
  color: white;
  height: 6em !important;
  width: 6em !important;
  border-radius: 50%;
  padding: 0.5em;
  display: flex;
  justify-content: center;

  border: 2px solid rgba(255, 255, 255, 0.2);

  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);

  &:hover {
    border: 2px solid rgba(255, 255, 255, 1);
  }

  > img {
    height: 45px;
    width: 45px;
    margin: auto auto;
  }

  @media screen and (max-width: 768px) {
    position: static;
  }
}

.contactsHeader {
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
  margin: 8em auto;
  height: 15em;
  width: 15em;
  border-radius: 50%;
  border: 3px solid white;

  h1 {
    margin: auto auto;
    color: white;
    font-size: 2.5em;
    font-weight: 800;
  }

  @media screen and (max-width: 768px) {
    margin: 5em auto 2em;
  }
}

.mobileBubbleContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1em;
}

/* KEYFRAMES */

@keyframes animateBubble {
  0% {
    margin-top: 600px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes sideWays {
  0% {
    margin-left: -10px;
  }
  100% {
    margin-left: 10px;
  }
}

/* ANIMATIONS */

.facebook {
  animation: animateBubble 5s linear;
  top: 20%;
  left: 50%;

  margin-left: -280px;
}

.twitter {
  animation: animateBubble 4s linear;
  top: 48%;
  left: 50%;

  margin-left: -233px;
}

.email {
  animation: animateBubble 3s linear;
  top: 65%;
  left: 50%;

  margin-left: -3.5em;
}

.github {
  animation: animateBubble 4s linear;
  top: 48%;
  left: 50%;

  margin-left: 120px;
}

.linkedin {
  animation: animateBubble 5s linear;
  top: 20%;
  left: 50%;

  margin-left: 163px;
}
