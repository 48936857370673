@import "../../Theme/colors.scss";

.navContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  flex-wrap: wrap;
  align-items: stretch;
  height: 2.5em;
  font-size: 90%;
  text-transform: uppercase;
  font-weight: bold;

  border-bottom: 1px solid $primary-link;

  margin: 0 auto;
  padding: 0;
  width: 100%;
  background: $white;
  list-style-type: none;

  position: fixed;
  z-index: 2;

  transition: all 0.3s;

  .hamburger {
    height: 2em !important;
    width: 2em !important;
  }

  .navToggle {
    padding: 2px !important;
    margin: 0 !important;
    background: none !important;
    border: none;

    @media screen and (max-width: 768px) {
      display: block;
      margin-left: auto;
    }
  }

  .navLink {
    display: block;
    color: $secondary-link;
    text-decoration: none;
    transition: all 0.3s;
    margin-right: 35px;
    cursor: pointer;
    align-self: center;

    &:hover {
      color: $secondary-link-hover;
    }

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }

  &.scrolledNavContainer {
    background: $white;

    .navLink {
      color: $secondary-link;

      &:hover {
        color: $secondary-link-hover;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    height: auto;

    background-color: $white;
    border-bottom: none;
  }

  &.afix {
    background-color: $white;
    border-bottom: none;

    a {
      color: $secondary-link;

      &:hover {
        color: $secondary-link-hover;
      }
    }
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: auto;

    @media screen and (max-width: 768px) {
      margin-right: 1px;
    }
  }

  div.navLink:not(:first-child) {
    display: flex;
    align-items: center;
  }

  .logoIcon {
    height: 2.5em;
    margin: auto 0;
  }

  div {
    height: 2.5em;
    color: #fff;

    &:nth-child(n + 2) {
      margin-right: 2em;
      @media screen and (max-width: 1024px) {
        margin: auto;
      }
    }
  }

  .navActive {
    background-color: #ccc;
    padding-right: 5px;
    padding-left: 5px;
    color: #4cbea7;
  }
}
