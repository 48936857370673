@import "../../Theme/colors.scss";

.musicPageContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 2em;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 3em 0 0;
  }
}

.header {
  color: $turquoise;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 150px;
  box-shadow: 2px 2px $turquoise-faded;
  border: 2px solid $turquoise;

  margin: 10px auto 60px;
  padding: 5px;

  > h1 {
    margin: 0;
  }
}

.introSection {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 700px;
  align-self: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  p {
    padding: 0 1em;
  }
}

.introParagraph {
  margin-top: 25px;
  padding: 0 1em;
}

.albumContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 700px;
  align-self: center;
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  p {
    padding: 0 1em;
  }
}

.playerContainer {
  width: 80%;
  max-width: 550px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.subHeader {
  color: $orange;
  text-align: center;
}
