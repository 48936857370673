@import "../../Theme/colors.scss";

.projectsContainer {
  min-height: 600px;
  background-color: $navy;
  padding: 2em 2em;
  display: flex;
  flex-direction: column;

  .header {
    color: $white;
    text-align: center;
    height: 50px;
    width: 150px;
    box-shadow: 2px 2px $white-faded;
    border: 2px solid $white;

    margin: 10px auto 60px;
    padding: 5px;

    align-self: center;
  }
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  width: 100%;
}

.experimentsContainer {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 100%;
}
