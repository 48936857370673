@import "../../Theme/colors.scss";

.headerContainer {
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: $white;
  background-position: center;
  background-size: cover;
  margin: 0 !important;

  .headerText {
    width: 100%;
    position: absolute;
    top: 85%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    h1 {
      margin: 0 !important;
    }

    h2 {
      margin: 0 !important;
      font-size: 2.5em;
    }
  }
}

.title {
  font-size: 6em;
  font-weight: bold;
  color: $orange;

  text-shadow: 2px 2px $white-faded;

  @media screen and (max-width: 768px) {
    font-size: 5em;
  }
}

.tentacleSign {
  position: absolute;
  height: 150px;
  right: 10px;
  top: 65%;

  transform: rotate(-40deg);
  animation: wink 1s alternate infinite;
}

@keyframes wink {
  0% {
    transform: rotate(-40deg);
  }

  100% {
    transform: rotate(-60deg);
  }
}
