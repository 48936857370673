$orange: #f05f40;
$orange-fade: rgba(240, 95, 64, 0.7);
$white: #fff;
$faded: rgba(0, 0, 0, 0.9);
$dark-grey: #444;
$light-grey: #ecf0f1;
$black: #000;
$blue: #4cbea7;
$white-faded: rgba(255, 255, 255, 0.7);
$transparent: rgba(0, 0, 0, 0);

$turquoise: #3fbef5;
$turquoise-faded: rgba(63, 190, 245, 0.7);
$navy: #2c3e50;

$primary-link: $white-faded;
$primary-link-hover: #fff;

$primary-text: $white;

$secondary-link: $black;
$secondary-active-link: $orange;
$secondary-link-hover: $orange;
