@import "../../Theme/colors.scss";

.articlesContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 3em 2em 2em;
}

.header {
  color: $turquoise;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 150px;
  box-shadow: 2px 2px $turquoise-faded;
  border: 2px solid $turquoise;

  margin: 10px auto 60px;
  padding: 5px;
}

.subSection {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 !important;
  margin-right: 0 !important;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.articleMenu {
  margin-right: 60px;
}

.articleContainer {
  max-width: 500px;
}
